<script>
	export default {
		onLaunch: function() {
			console.log('App Launch')
			// 初始化时不加载 supabase
			uni.setStorageSync('initApp', true)
		},
		onShow: function() {
			console.log('App Show')
		},
		onHide: function() {
			console.log('App Hide')
		},
		// 捕获全局错误
		onError(err) {
			console.error('App Error:', err)
		}
	}
</script>

<style>
	/*每个页面公共css */
</style>
